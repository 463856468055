<template>
    <div class="wrapper">
        <div class="banner">
            <img src="../../../assets/img/home/banner.png" alt="" />
        </div>
        <!-- <div class="breadcrumb_box">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/enterpriseService' }">基础服务</el-breadcrumb-item>
                <el-breadcrumb-item>法律服务</el-breadcrumb-item>
            </el-breadcrumb>
        </div> -->

        <div class="containertitle">
            <div class="container_title">工商变更服务</div>
            <div class="container_dsc">绿色办理通道快至7个工作日变更成功</div>
        </div>



        <div class="containerContent">
            <div class="containerbox">
                <div class="containerbg">
                    <img src="../../../assets/img/home/industry/bgfw1.png" style="width: 100%;height: 100%;" alt="" />
                </div>
                <div class="containerName">公司名称变更</div>
            </div>

            <div class="containerbox">
                <div class="containerbg">
                    <img src="../../../assets/img/home/industry/bgfw2.png" style="width: 100%;height: 100%;" alt="" />
                </div>
                <div class="containerName">公司地址变更</div>
            </div>

            <div class="containerbox">
                <div class="containerbg">
                    <img src="../../../assets/img/home/industry/bgfw3.png" style="width: 100%;height: 100%;"
                        alt="" />
                </div>
                <div class="containerName">公司股东变更</div>
            </div>

            <div class="containerbox">
                <div class="containerbg">
                    <img src="../../../assets/img/home/industry/bgfw1.png" style="width: 100%;height: 100%;" alt="" />
                </div>
                <div class="containerName">公司法人变更</div>
            </div>

            <div class="containerbox">
                <div class="containerbg">
                    <img src="../../../assets/img/home/industry/bgfw5.png" style="width: 100%;height: 100%;" alt="" />
                </div>
                <div class="containerName">经营范围变更</div>
            </div>

            <div class="containerbox">
                <div class="containerbg">
                    <img src="../../../assets/img/home/industry/bgfw6.png" style="width: 100%;height: 100%;" alt="" />
                </div>
                <div class="containerName">注册资金变更</div>
            </div>
        </div>


        <div class="containertitle">
            <div class="container_title">企业未及时变更 将存在四大风险</div>
            <div class="container_dsc">只要营业执照信息发生变化，务必进行变更，避免一切风险</div>
        </div>



        <div class="riskMessage">
            <div class="riskMessagebox riskMessagebg1">
                <div class="riskMessage_title">面临罚款</div>
                <div class="riskMessage_dsc">逾期不登记的，处以1万元以上 10万元以下的罚款。</div>
            </div>

            <div class="riskMessagebox riskMessagebg2">
                <div class="riskMessage_title">承担败诉风险</div>
                <div class="riskMessage_dsc">逾期不变更的，未接收法院文 书,无法出庭可能承担败诉风险</div>
            </div>

            <div class="riskMessagebox riskMessagebg3">
                <div class="riskMessage_title">吊销营业执照</div>
                <div class="riskMessage_dsc">遍期不办理的，估节严重的吊 销营业执照</div>
            </div>

            <div class="riskMessagebox riskMessagebg4">
                <div class="riskMessage_title">无法收债</div>
                <div class="riskMessage_dsc">注册登记地和经营地不一致， 可能导致债务履行困难</div>
            </div>
        </div>



        <div class="containertitle">
            <div class="container_title">工商变更 5步一站式搞定</div>
            <div class="container_dsc">全程加急节省您的时间，一站式搞定变更流程</div>
        </div>



        <div class="Stuffcollect">
            <div class="Stuffcollectbox">
                <div class="Stuffcollectbg Stuffcollectbg1"></div>
                <div class="Stuffcollectdscbox">
                    <div class="Stuffcollect_title">收集资料</div>
                    <div class="Stuffcollectdsc">了解变更业务</div>
                </div>

            </div>


            <div class="Stuffcollectbox">
                <div class="Stuffcollectdscbox">
                    <div class="Stuffcollectdsc">免费网上登记</div>
                    <div class="Stuffcollect_title">网上登记</div>
                </div>
                <div class="Stuffcollectbg Stuffcollectbg2"></div>

            </div>

            <div class="Stuffcollectbox">
                <div class="Stuffcollectbg Stuffcollectbg3"></div>

                <div class="Stuffcollectdscbox">
                    <div class="Stuffcollect_title">材料提交</div>
                    <div class="Stuffcollectdsc">提交哪些材料</div>
                </div>

            </div>

            <div class="Stuffcollectbox">
                <div class="Stuffcollectdscbox">
                    <div class="Stuffcollectdsc">领取哪些证件</div>
                    <div class="Stuffcollect_title">领取执照</div>
                </div>
                <div class="Stuffcollectbg Stuffcollectbg4"></div>

            </div>

            <div class="Stuffcollectbox">
                <div class="Stuffcollectbg Stuffcollectbg5"></div>

                <div class="Stuffcollectdscbox">
                    <div class="Stuffcollect_title">办理成功</div>
                    <div class="Stuffcollectdsc">我要办理</div>
                </div>

            </div>

            <div class="StuffcollectLine"></div>


        </div>




        <div class="process_btn">立即咨询</div>


    </div>
</template>

<script>
export default {
    components: {},
    props: {},
    data() {
        return {
            dialogVisible: false,
            registerPhone: "",
            formInline: {
                address: "太原",
                phone: "",
                acctName: "",
            },




        };
    },
    watch: {},
    computed: {},
    methods: {
        onSubmit() {
            console.log("submit!");
        },
        // 一键办理注册
        handleRegister() {
            this.dialogVisible = true;
        },
    },
    created() { },
    mounted() { },
};
</script>
<style scoped>
.banner {
    width: 100%;
    height: 300px;
}

.banner img {
    display: block;
    width: 100%;
    height: 100%;
}

.breadcrumb_box {
    width: 1200px;
    padding: 30px 0 0;
    margin: 0 auto;
}

.container {
    padding: 60px;
}

.container_title {
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    margin-top: 20px
}

.container_dsc {
    margin: 20px auto;
    width: 40%;
    text-align: center;
    font-size: 18px;
    color: #055AFE;

}


.containerContent {
    width: 60%;
    margin: 4% auto;
    display: flex;
    flex-wrap: wrap;

}


.containerbox {
    height: 66px;
    width: 14%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    margin: 0 auto;

    /* border: 1px solid red; */
}

.containerbg {
    width: 50%;
    margin-bottom: 4%;
}

.containerName {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

}


.riskMessage {
    width: 70%;
    height: 290px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5% auto;
}

.riskMessagebox {
    width: 23%;
    margin: 0 1%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    padding-bottom: 2%;
}

.riskMessage_title {
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #055AFE;
    flex: 2;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 15%;

}

.riskMessage_dsc {
    width: 80%;
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #848484;
    flex: 1;

}

.riskMessagebg1 {
    background: url("../../../assets/img/home/industry/01.png") no-repeat 50% 50%;
    background-size: 100% 100%;
    height: 100%;
}

.riskMessagebg2 {
    background: url("../../../assets/img/home/industry/02.png") no-repeat 50% 50%;
    background-size: 100% 100%;
    height: 100%;
}

.riskMessagebg3 {
    background: url("../../../assets/img/home/industry/03.png") no-repeat 50% 50%;
    background-size: 100% 100%;
    height: 100%;
}

.riskMessagebg4 {
    background: url("../../../assets/img/home/industry/04.png") no-repeat 50% 50%;
    background-size: 100% 100%;
    height: 100%;
}



.Stuffcollect {
    width: 70%;
    height: 200px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin: 5% auto;
    position: relative;
}

.StuffcollectLine {

    position: absolute;
    top: 50%;
    width: 80%;
    height: 2px;
    background: #055AFE;

}

.Stuffcollectbox {
    width: 25%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
}

.Stuffcollectdscbox {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    flex: 1;
}

.Stuffcollect_title {
    font-size: 18px;
    font-family: PingFang SC;
    color: #000000;
    margin: 5% 0;
}

.Stuffcollectdsc {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #055AFE;
    /* width: 80%; */
    border: 1px solid #055AFE;
    border-radius: 10px;
    text-align: center;
    padding: 5px 10px;
}

.Stuffcollectbg {
    flex: 1;
    height: 100%;
    width: 69px;
    /* height: 110px; */
}

.Stuffcollectbg1 {
    background: url("../../../assets/img/home/industry/gd1.png") no-repeat 50% 50%;
    background-size: 100% 100%;
    padding-top: 12%;
    z-index: 99;

}

.Stuffcollectbg2 {
    background: url("../../../assets/img/home/industry/gd2.png") no-repeat 50% 50%;
    background-size: 100% 100%;
    padding-bottom: 12%;
    z-index: 99;
}

.Stuffcollectbg3 {
    background: url("../../../assets/img/home/industry/gd3.png") no-repeat 50% 50%;
    background-size: 100% 100%;
    padding-top: 12%;
    z-index: 99;
}

.Stuffcollectbg4 {
    background: url("../../../assets/img/home/industry/gd4.png") no-repeat 50% 50%;
    background-size: 100% 100%;
    padding-bottom: 12%;
    z-index: 99;
}

.Stuffcollectbg5 {
    background: url("../../../assets/img/home/industry/gd5.png") no-repeat 50% 50%;
    background-size: 100% 100%;
    padding-bottom: 12%;
    z-index: 99;
}






.process_btn {
    width: 132px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    border-radius: 28px;
    background: #055afe;
    cursor: pointer;
    color: #fff;
    margin: 0 auto;
    margin-top: 60px;
}
</style>
